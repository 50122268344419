/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_content_vjswe_uyta3_153:not(#\9) {
  /* used in test-utils */
}

.awsui_button_vjswe_uyta3_157:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-eynpfp, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  min-inline-size: 0;
  word-break: break-word;
  font-weight: var(--font-weight-button-c91p4t, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  letter-spacing: var(--font-button-letter-spacing-65ug0o, 0.005em);
  border-start-start-radius: var(--border-radius-button-ypmfry, 20px);
  border-start-end-radius: var(--border-radius-button-ypmfry, 20px);
  border-end-start-radius: var(--border-radius-button-ypmfry, 20px);
  border-end-end-radius: var(--border-radius-button-ypmfry, 20px);
  border-block: var(--border-width-field-h1g7tw, 2px) solid;
  border-inline: var(--border-width-field-h1g7tw, 2px) solid;
  padding-block: var(--space-scaled-xxs-7597g1, 4px);
  padding-inline: var(--space-button-horizontal-8jxzea, 20px);
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-normal_vjswe_uyta3_205:not(#\9) {
  background: var(--color-background-button-normal-default-zr63bz, #ffffff);
  color: var(--color-text-button-normal-default-9ggr0r, #0972D3);
  border-color: var(--color-border-button-normal-default-o6m7rm, #0972D3);
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-normal_vjswe_uyta3_205:not(#\9):hover {
  background: var(--color-background-button-normal-hover-eqpcl2, #f0fbff);
  color: var(--color-text-button-normal-hover-98i8lw, #033160);
  border-color: var(--color-border-button-normal-hover-zagtpo, #033160);
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-normal_vjswe_uyta3_205:not(#\9):active {
  background: var(--color-background-button-normal-active-udb472, #d1f1ff);
  color: var(--color-text-button-normal-active-lijk4b, #007D7B);
  border-color: var(--color-border-button-normal-active-mn0ayd, #002b66);
}
.awsui_button_vjswe_uyta3_157.awsui_variant-normal_vjswe_uyta3_205.awsui_disabled_vjswe_uyta3_223:not(#\9) {
  background: var(--color-background-button-normal-disabled-h6arvb, #ffffff);
  border-color: var(--color-border-button-normal-disabled-caqkhj, #b4b4bb);
  color: var(--color-text-button-normal-disabled-0s30to, #8c8c94);
  text-decoration: none;
  cursor: auto;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-primary_vjswe_uyta3_230:not(#\9) {
  background: var(--color-background-button-primary-default-8suzba, #0972D3);
  color: var(--color-text-button-primary-default-t0t2sj, #ffffff);
  border-color: var(--color-background-button-primary-default-8suzba, #0972D3);
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-primary_vjswe_uyta3_230:not(#\9):hover {
  background: var(--color-background-button-primary-hover-wes3vt, #033160);
  color: var(--color-text-button-primary-hover-5z07s7, #ffffff);
  border-color: var(--color-background-button-primary-hover-wes3vt, #033160);
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-primary_vjswe_uyta3_230:not(#\9):active {
  background: var(--color-background-button-primary-active-wo7a7e, #007D7B);
  color: var(--color-text-button-primary-active-l1tdpc, #ffffff);
  border-color: var(--color-background-button-primary-active-wo7a7e, #007D7B);
}
.awsui_button_vjswe_uyta3_157.awsui_variant-primary_vjswe_uyta3_230.awsui_disabled_vjswe_uyta3_223:not(#\9) {
  background: var(--color-background-button-primary-disabled-l4i17m, #ebebf0);
  border-color: var(--color-border-button-primary-disabled-xtv38q, #ebebf0);
  color: var(--color-text-button-primary-disabled-klolb5, #8c8c94);
  text-decoration: none;
  cursor: auto;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-link_vjswe_uyta3_255:not(#\9) {
  background: transparent;
  color: var(--color-text-button-normal-default-9ggr0r, #0972D3);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-link_vjswe_uyta3_255:not(#\9):hover {
  background: var(--color-background-button-link-hover-7joibg, #f0fbff);
  color: var(--color-text-button-normal-hover-98i8lw, #033160);
  border-color: var(--color-background-button-link-hover-7joibg, #f0fbff);
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-link_vjswe_uyta3_255:not(#\9):active {
  background: var(--color-background-button-link-active-zsvqyt, #d1f1ff);
  color: var(--color-text-button-normal-active-lijk4b, #007D7B);
  border-color: var(--color-background-button-link-active-zsvqyt, #d1f1ff);
}
.awsui_button_vjswe_uyta3_157.awsui_variant-link_vjswe_uyta3_255.awsui_disabled_vjswe_uyta3_223:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
  text-decoration: none;
  cursor: auto;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-icon_vjswe_uyta3_280:not(#\9) {
  background: transparent;
  color: var(--color-text-interactive-default-tkx8fe, #424650);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-icon_vjswe_uyta3_280:not(#\9):hover {
  background: transparent;
  color: var(--color-text-interactive-hover-f9gqs8, #0f141a);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-icon_vjswe_uyta3_280:not(#\9):active {
  background: transparent;
  color: var(--color-text-interactive-default-tkx8fe, #424650);
  border-color: transparent;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-icon_vjswe_uyta3_280.awsui_disabled_vjswe_uyta3_223:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
  text-decoration: none;
  cursor: auto;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-icon_vjswe_uyta3_305:not(#\9) {
  background: transparent;
  color: var(--color-text-button-inline-icon-default-fqnuri, #0972D3);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-icon_vjswe_uyta3_305:not(#\9):hover {
  background: transparent;
  color: var(--color-text-button-inline-icon-hover-sv9cnz, #033160);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-icon_vjswe_uyta3_305:not(#\9):active {
  background: transparent;
  color: var(--color-text-button-inline-icon-default-fqnuri, #0972D3);
  border-color: transparent;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-icon_vjswe_uyta3_305.awsui_disabled_vjswe_uyta3_223:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-button-inline-icon-disabled-yk9lvs, #b4b4bb);
  text-decoration: none;
  cursor: auto;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-icon-pointer-target_vjswe_uyta3_330:not(#\9) {
  background: transparent;
  color: var(--color-text-button-inline-icon-default-fqnuri, #0972D3);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-icon-pointer-target_vjswe_uyta3_330:not(#\9):hover {
  background: transparent;
  color: var(--color-text-button-inline-icon-hover-sv9cnz, #033160);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-icon-pointer-target_vjswe_uyta3_330:not(#\9):active {
  background: transparent;
  color: var(--color-text-button-inline-icon-default-fqnuri, #0972D3);
  border-color: transparent;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-icon-pointer-target_vjswe_uyta3_330.awsui_disabled_vjswe_uyta3_223:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-button-inline-icon-disabled-yk9lvs, #b4b4bb);
  text-decoration: none;
  cursor: auto;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-link_vjswe_uyta3_355:not(#\9) {
  background: transparent;
  color: var(--color-text-button-normal-default-9ggr0r, #0972D3);
  border-color: transparent;
  border-block-width: 0;
  border-inline-width: 0;
  position: relative;
  text-decoration: none;
  padding-block: 0;
  padding-inline: 0;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-link_vjswe_uyta3_355:not(#\9):hover {
  background: transparent;
  color: var(--color-text-button-normal-hover-98i8lw, #033160);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-link_vjswe_uyta3_355:not(#\9):active {
  background: transparent;
  color: var(--color-text-button-normal-active-lijk4b, #007D7B);
  border-color: transparent;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-link_vjswe_uyta3_355.awsui_disabled_vjswe_uyta3_223:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
  text-decoration: none;
  cursor: auto;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-modal-dismiss_vjswe_uyta3_384:not(#\9) {
  background: transparent;
  color: var(--color-text-interactive-default-tkx8fe, #424650);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-modal-dismiss_vjswe_uyta3_384:not(#\9):hover {
  background: transparent;
  color: var(--color-text-interactive-hover-f9gqs8, #0f141a);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-modal-dismiss_vjswe_uyta3_384:not(#\9):active {
  background: transparent;
  color: var(--color-text-interactive-default-tkx8fe, #424650);
  border-color: transparent;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-modal-dismiss_vjswe_uyta3_384.awsui_disabled_vjswe_uyta3_223:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
  text-decoration: none;
  cursor: auto;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-flashbar-icon_vjswe_uyta3_409:not(#\9) {
  background: transparent;
  color: var(--color-text-interactive-inverted-default-8v8d60, #dedee3);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-flashbar-icon_vjswe_uyta3_409:not(#\9):hover {
  background: transparent;
  color: var(--color-text-interactive-inverted-hover-0r4deg, #f9f9fa);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-flashbar-icon_vjswe_uyta3_409:not(#\9):active {
  background: transparent;
  color: var(--color-text-interactive-inverted-default-8v8d60, #dedee3);
  border-color: transparent;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-flashbar-icon_vjswe_uyta3_409.awsui_disabled_vjswe_uyta3_223:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
  text-decoration: none;
  cursor: auto;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-breadcrumb-group_vjswe_uyta3_434:not(#\9) {
  background: transparent;
  color: var(--color-text-link-default-n722mo, #0972D3);
  border-color: transparent;
  border-block-width: 0;
  border-inline-width: 0;
  position: relative;
  text-decoration: none;
  padding-block: 0;
  padding-inline: 0;
  font-weight: normal;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-breadcrumb-group_vjswe_uyta3_434:not(#\9):hover {
  background: transparent;
  color: var(--color-text-link-hover-6f5hec, #033160);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-breadcrumb-group_vjswe_uyta3_434:not(#\9):active {
  background: transparent;
  color: var(--color-text-link-default-n722mo, #0972D3);
  border-color: transparent;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-breadcrumb-group_vjswe_uyta3_434.awsui_disabled_vjswe_uyta3_223:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
  text-decoration: none;
  cursor: auto;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-menu-trigger_vjswe_uyta3_464:not(#\9) {
  background: transparent;
  color: var(--color-text-button-normal-default-9ggr0r, #0972D3);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-menu-trigger_vjswe_uyta3_464:not(#\9):hover {
  background: transparent;
  color: var(--color-text-button-normal-hover-98i8lw, #033160);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-menu-trigger_vjswe_uyta3_464:not(#\9):active {
  background: transparent;
  color: var(--color-text-button-normal-active-lijk4b, #007D7B);
  border-color: transparent;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-menu-trigger_vjswe_uyta3_464.awsui_disabled_vjswe_uyta3_223:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
  text-decoration: none;
  cursor: auto;
}
.awsui_button_vjswe_uyta3_157:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-cqfd0c, 4px) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  inset-block-start: calc(-1 * var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  inline-size: calc(100% + var(--space-button-focus-outline-gutter-cqfd0c, 4px) + var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  block-size: calc(100% + var(--space-button-focus-outline-gutter-cqfd0c, 4px) + var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-icon_vjswe_uyta3_280, body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-modal-dismiss_vjswe_uyta3_384, body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-flashbar-icon_vjswe_uyta3_409 {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-icon_vjswe_uyta3_280, body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-modal-dismiss_vjswe_uyta3_384, body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-flashbar-icon_vjswe_uyta3_409 {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-cqfd0c, 4px) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-icon_vjswe_uyta3_280::before, body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-modal-dismiss_vjswe_uyta3_384::before, body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-flashbar-icon_vjswe_uyta3_409::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  inset-block-start: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-zgqy8m, 0px));
  inline-size: calc(100% + var(--space-button-focus-outline-gutter-cqfd0c, 4px) + var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  block-size: calc(100% + var(--space-button-icon-focus-outline-gutter-vertical-zgqy8m, 0px) + var(--space-button-icon-focus-outline-gutter-vertical-zgqy8m, 0px));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-inline-icon_vjswe_uyta3_305, body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-inline-icon-pointer-target_vjswe_uyta3_330 {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-inline-icon_vjswe_uyta3_305, body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-inline-icon-pointer-target_vjswe_uyta3_330 {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-inline-icon_vjswe_uyta3_305::before, body[data-awsui-focus-visible=true] .awsui_button_vjswe_uyta3_157:not(#\9):focus.awsui_variant-inline-icon-pointer-target_vjswe_uyta3_330::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px));
  inset-block-start: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px));
  inline-size: calc(100% + var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px) + var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px));
  block-size: calc(100% + var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px) + var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_button_vjswe_uyta3_157.awsui_button-no-text_vjswe_uyta3_556:not(#\9) {
  padding-inline-start: var(--space-button-icon-only-horizontal-zsf2nw, 6px);
  padding-inline-end: var(--space-button-icon-only-horizontal-zsf2nw, 6px);
}
.awsui_button_vjswe_uyta3_157.awsui_button-no-wrap_vjswe_uyta3_560:not(#\9) {
  white-space: nowrap;
}
.awsui_button_vjswe_uyta3_157.awsui_full-width_vjswe_uyta3_563:not(#\9) {
  inline-size: 100%;
  text-align: center;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-icon_vjswe_uyta3_280:not(#\9), .awsui_button_vjswe_uyta3_157.awsui_variant-inline-icon_vjswe_uyta3_305:not(#\9), .awsui_button_vjswe_uyta3_157.awsui_variant-inline-icon-pointer-target_vjswe_uyta3_330:not(#\9), .awsui_button_vjswe_uyta3_157.awsui_variant-flashbar-icon_vjswe_uyta3_409:not(#\9) {
  padding-inline: var(--space-xxs-p8yyaw, 4px);
}
.awsui_button_vjswe_uyta3_157.awsui_variant-modal-dismiss_vjswe_uyta3_384:not(#\9) {
  padding-block: var(--space-button-modal-dismiss-vertical-0n4y5m, 2px);
  padding-inline: var(--space-xxs-p8yyaw, 4px);
  margin-inline-end: calc(-1 * var(--space-xxs-p8yyaw, 4px));
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-icon_vjswe_uyta3_305:not(#\9) {
  padding-block: 0;
  border-block: 0;
  border-inline: 0;
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-icon-pointer-target_vjswe_uyta3_330:not(#\9) {
  padding-block: var(--space-xxxs-zbmxqb, 2px);
  border-block: 0;
  border-inline: 0;
}
.awsui_button_vjswe_uyta3_157 > .awsui_icon-left_vjswe_uyta3_585:not(#\9) {
  position: relative;
  inset-inline-start: calc(-1 * var(--space-xxs-p8yyaw, 4px));
  margin-inline-end: var(--space-xxs-p8yyaw, 4px);
}
.awsui_button_vjswe_uyta3_157 > .awsui_icon-right_vjswe_uyta3_590:not(#\9) {
  position: relative;
  inset-inline-end: calc(-1 * var(--space-xxs-p8yyaw, 4px));
  margin-inline-start: var(--space-xxs-p8yyaw, 4px);
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-link_vjswe_uyta3_355 > .awsui_icon-left_vjswe_uyta3_585:not(#\9) {
  inset-inline-start: 0;
  margin-inline-end: var(--space-xs-zb16t3, 8px);
}
.awsui_button_vjswe_uyta3_157.awsui_variant-inline-link_vjswe_uyta3_355 > .awsui_icon-right_vjswe_uyta3_590:not(#\9) {
  inset-inline-end: 0;
  margin-inline-start: var(--space-xs-zb16t3, 8px);
}
.awsui_button_vjswe_uyta3_157.awsui_button-no-text_vjswe_uyta3_556 > .awsui_icon_vjswe_uyta3_585:not(#\9) {
  margin-inline-start: auto;
  margin-inline-end: auto;
  inset-inline: 0;
}

.awsui_link_vjswe_uyta3_609.awsui_disabled_vjswe_uyta3_223:not(#\9):not(.awsui_disabled-with-reason_vjswe_uyta3_609) {
  pointer-events: none;
}